import {PageResponse} from "../common/types";

export interface FetchKindOfProductRecalculationPageRequest {
    page: number;
    size: number;
    sort: { field: string, direction: 'ASC' | 'DESC' };
}

export interface FetchKindOfProductRecalculationPageResponse extends PageResponse {
    content: KopRecalculation[];
}

export interface CreateKindOfProductRecalculationRequest {
    globalProductIds: number[];
}

export interface ApplyKindOfProductRecalculationRequest {
    kopRecalculationId: number;
}

export interface KopRecalculation {
    id: number;
    kopRecalculationStatus: KopRecalculationStatus;
    createdAt: Date,
    updatedAt: Date,
    createdByFullName: string,
    updatedByFullName: string
}

export interface KopRecalculationReport {
    kopRecalculationId: number;
    pdgNotificationList: number[];
    globalProductRecalculationDTOs: GlobalProductRecalculationDTO[];
}

export enum KopRecalculationStatus {
    RUNNING,
    PENDING_APPROVAL,
    BEING_APPLIED,
    COMPLETED,
    REJECTED,
    INVALID_KOP,
    ERROR
}

export enum KopResolutionStatus {
    STATIC_MAPPING,
    PREDICTION,
    USER_INPUT,
    MISSING_GLOBAL_PRODUCT,
    INCAPACITATE_RECALCULATION
}

export interface GlobalProductRecalculationDTO {
    globalProductId: String;
    oldKindOfProduct: String;
    newKindOfProduct: String
    kopResolutionStatus: KopResolutionStatus;
}

export const FETCH_KIND_OF_PRODUCT_RECALCULATIONS = 'FETCH_KIND_OF_PRODUCT_RECALCULATIONS'
export const FETCH_KIND_OF_PRODUCT_RECALCULATIONS_SUCCEEDED = 'FETCH_KIND_OF_PRODUCT_RECALCULATIONS_SUCCEEDED'
export const FETCH_KIND_OF_PRODUCT_RECALCULATIONS_FAILED = 'FETCH_KIND_OF_PRODUCT_RECALCULATIONS_FAILED'
export const CREATE_KIND_OF_PRODUCT_RECALCULATION = 'CREATE_KIND_OF_PRODUCT_RECALCULATION'
export const CREATE_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED = 'CREATE_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED'
export const CREATE_KIND_OF_PRODUCT_RECALCULATION_FAILED = 'CREATE_KIND_OF_PRODUCT_RECALCULATION_FAILED'
export const DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT = 'DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT'
export const DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT_SUCCEEDED = 'DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT_SUCCEEDED'
export const DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT_FAILED = 'DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT_FAILED'
export const UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT = 'UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT'
export const UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT_SUCCEEDED = 'UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT_SUCCEEDED'
export const UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT_FAILED = 'UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT_FAILED'
export const APPLY_KIND_OF_PRODUCT_RECALCULATION = 'APPLY_KIND_OF_PRODUCT_RECALCULATION'
export const APPLY_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED = 'APPLY_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED'
export const APPLY_KIND_OF_PRODUCT_RECALCULATION_FAILED = 'APPLY_KIND_OF_PRODUCT_RECALCULATION_FAILED'
export const REJECT_KIND_OF_PRODUCT_RECALCULATION = 'REJECT_KIND_OF_PRODUCT_RECALCULATION'
export const REJECT_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED = 'REJECT_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED'
export const REJECT_KIND_OF_PRODUCT_RECALCULATION_FAILED = 'REJECT_KIND_OF_PRODUCT_RECALCULATION_FAILED'


export interface FetchKindOfProductRecalculationsAction {
    type: typeof FETCH_KIND_OF_PRODUCT_RECALCULATIONS
    pageRequest: FetchKindOfProductRecalculationPageRequest
}

export interface FetchKindOfProductRecalculationsSucceededAction {
    type: typeof FETCH_KIND_OF_PRODUCT_RECALCULATIONS_SUCCEEDED,
    payload: FetchKindOfProductRecalculationPageResponse
}

export interface FetchKindOfProductRecalculationsFailedAction {
    type: typeof FETCH_KIND_OF_PRODUCT_RECALCULATIONS_FAILED,
    error: string
}

export interface CreateKindOfProductRecalculationsAction {
    type: typeof CREATE_KIND_OF_PRODUCT_RECALCULATION
    request: CreateKindOfProductRecalculationRequest
}

export interface CreateKindOfProductRecalculationsSucceededAction {
    type: typeof CREATE_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED,
    payload: KopRecalculation
}

export interface CreateKindOfProductRecalculationsFailedAction {
    type: typeof CREATE_KIND_OF_PRODUCT_RECALCULATION_FAILED,
    error: string
}

export interface DownloadKindOfProductRecalculationReportAction {
    type: typeof DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT
    request: number
}

export interface DownloadKindOfProductRecalculationReportSucceededAction {
    type: typeof DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT_SUCCEEDED,
    payload: Boolean
}

export interface DownloadKindOfProductRecalculationReportFailedAction {
    type: typeof DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT_FAILED,
    error: string
}

export interface UpdateKindOfProductRecalculationReportAction {
    type: typeof UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT
    request: File
}

export interface UpdateKindOfProductRecalculationReportSucceededAction {
    type: typeof UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT_SUCCEEDED,
    payload: KopRecalculation
}

export interface UpdateKindOfProductRecalculationReportFailedAction {
    type: typeof UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT_FAILED,
    error: string
}

export interface ApplyKindOfProductRecalculationAction {
    type: typeof APPLY_KIND_OF_PRODUCT_RECALCULATION
    request: ApplyKindOfProductRecalculationRequest
}

export interface ApplyKindOfProductRecalculationSucceededAction {
    type: typeof APPLY_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED,
    payload: KopRecalculation
}

export interface ApplyKindOfProductRecalculationFailedAction {
    type: typeof APPLY_KIND_OF_PRODUCT_RECALCULATION_FAILED,
    error: string
}

export interface RejectKindOfProductRecalculationAction {
    type: typeof REJECT_KIND_OF_PRODUCT_RECALCULATION
    request: number
}

export interface RejectKindOfProductRecalculationSucceededAction {
    type: typeof REJECT_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED,
    payload: KopRecalculation
}

export interface RejectKindOfProductRecalculationFailedAction {
    type: typeof REJECT_KIND_OF_PRODUCT_RECALCULATION_FAILED,
    error: string
}

export type KindOfProductRecalculationsActionTypes =
    FetchKindOfProductRecalculationsAction
    | FetchKindOfProductRecalculationsSucceededAction
    | FetchKindOfProductRecalculationsFailedAction
    | CreateKindOfProductRecalculationsAction
    | CreateKindOfProductRecalculationsSucceededAction
    | CreateKindOfProductRecalculationsFailedAction
    | DownloadKindOfProductRecalculationReportAction
    | DownloadKindOfProductRecalculationReportSucceededAction
    | DownloadKindOfProductRecalculationReportFailedAction
    | UpdateKindOfProductRecalculationReportAction
    | UpdateKindOfProductRecalculationReportSucceededAction
    | UpdateKindOfProductRecalculationReportFailedAction
    | ApplyKindOfProductRecalculationAction
    | ApplyKindOfProductRecalculationSucceededAction
    | ApplyKindOfProductRecalculationFailedAction
    | RejectKindOfProductRecalculationAction
    | RejectKindOfProductRecalculationSucceededAction
    | RejectKindOfProductRecalculationFailedAction

export interface KindOfProductRecalculationState {
    kopRecalculation: KopRecalculation;

    pageRequest: FetchKindOfProductRecalculationPageRequest;
    pageResponse: FetchKindOfProductRecalculationPageResponse;

    globalProductIDs: string;

    loading: boolean;
    error: string;
}

