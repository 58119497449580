import axiosInstance from '../axiosInstance'
import { AxiosInstance } from "axios";
import { UserSession } from "../store/userSession/types";
import { resolveApiBaseUrl } from "../environment";

class UserService {
  axios: AxiosInstance;

  constructor () {
    this.axios = axiosInstance;
  }

  getUserSession(): Promise<UserSession> {
    return this.axios.get('/user/session', {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return {
          loggedIn: true,
          firstname: response['data'].firstname,
          lastname: response['data'].lastname,
          email: response['data'].email,
          user: response['data'].user,
          permissions: response['data'].permissions,
          expiresIn: response['data'].expiresIn
        }
      })
      .catch((error: any) => {
        if (error.response && error.response.status === 403) {
          return {
            loggedIn: false,
            firstname: null,
            lastname: null,
            email: null,
            user: null,
            permissions: null,
            expiresIn: 0
          }
        } else {
          this.handleError(error);
          throw error;
        }
      })
  }

  handleError (error: any) {
    console.error('UserService:', error)
  }
}

export default new UserService()
