import * as React from "react";
import "./KindOfProductRecalculationView.css"
import EnhancedTable, {Column, Order} from "../EnhancedTable/EnhancedTable";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

import {Button, Form, Input, message} from "antd";
import {
    FetchKindOfProductRecalculationPageRequest,
    FetchKindOfProductRecalculationPageResponse, KopRecalculation
} from "../../store/kindOfProductRecalculation/types";
import {InboxOutlined} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import {RcCustomRequestOptions} from "antd/es/upload/interface";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

export interface KindOfProductRecalculationViewProps {
    pageResponse: FetchKindOfProductRecalculationPageResponse,
    pageRequest: FetchKindOfProductRecalculationPageRequest,
    onPaginationChanged: (page: number, size: number) => void,
    loading: boolean,
    error: string,
    globalProductIDs: string,
    onFormFieldChange: (field: string, value: string) => void;
    onSubmit: () => void,
    onDownloadReport: (id: number) => void;
    onFileUpload: (file: File) => void;
    onApplyKindOfProductRecalculation: (id: number) => void;
    onRejectKindOfProductRecalculation: (id: number) => void;
    onSortingChanged?: (orderBy: string, order: Order) => void;
}

export const KindOfProductRecalculationView: React.FunctionComponent<KindOfProductRecalculationViewProps> = (props) => {

    const [selectedId, setSelectedId] = React.useState<number | null>(null);
    const [uploadFileDialogOpen, setUploadFileDialogOpen] = React.useState(false);
    const [applyDialogOpen, setApplyDialogOpen] = React.useState(false);

    function handleClickOpen(): void {
        setUploadFileDialogOpen(true);
    };

    const handleClose = () => {
        setUploadFileDialogOpen(false);
    };

    function handleApplyDialogClickOpen(id: number): void {
        setSelectedId(id);
        setApplyDialogOpen(true);
    };

    const handleApplyDialogClose = () => {
        setApplyDialogOpen(false);
    };

    const handleApplyDialogConfirm = () => {
        handleApplyDialogClose();
        onApplyKindOfProductRecalculation(selectedId!);
    };

    const [form] = Form.useForm();

    const formItemLayout = {
        labelCol: {span: 3},
        wrapperCol: {span: 7},
    };

    const buttonItemLayout = {
        wrapperCol: {span: 5, offset: 3},
    };

    const {
        pageRequest,
        pageResponse,
        globalProductIDs,
        onFormFieldChange,
        onSortingChanged,
        onPaginationChanged,
        onSubmit,
        onDownloadReport,
        onFileUpload,
        onApplyKindOfProductRecalculation,
        onRejectKindOfProductRecalculation,
        loading,
        error
    } = props;

    const columns: Column[] = [
        {
            label: 'Recalculation ID',
            field: 'id'
        },
        {label: 'Status', field: 'kopRecalculationStatus', sortable: false},
        {
            label: 'Created At',
            field: 'createdAt',
            type: 'computed',
            computedValue: (kopRecalculation: KopRecalculation) => {
                return (<a>{new Date(kopRecalculation.createdAt).toLocaleString()}</a>)
            }
        },
        {
            label: 'Created By',
            field: 'createdBy',
            type: 'computed',
            computedValue: (kopRecalculation: KopRecalculation) => {
                return (<a>{kopRecalculation.createdByFullName}</a>)
            }
        },
        {
            label: 'Updated At',
            field: 'updatedAt',
            type: 'computed',
            computedValue: (kopRecalculation: KopRecalculation) => {
                return (<a>{new Date(kopRecalculation.updatedAt).toLocaleString()}</a>)
            }
        },
        {
            label: 'Updated By',
            field: 'updatedBy',
            type: 'computed',
            computedValue: (kopRecalculation: KopRecalculation) => {
                return (<a>{kopRecalculation.updatedByFullName}</a>)
            }
        },
        {
            label: 'Action',
            field: 'action',
            type: 'computed',
            sortable: false,
            computedValue: (kopRecalculation: KopRecalculation) => {
                const downloadBtn = <><Button type="primary" onClick={() => onDownloadReport(kopRecalculation.id)}>Download Report</Button> </>;
                const updateBtn = <><Button type="primary" style={{backgroundColor: "orange", borderColor: "orange"}}
                                            onClick={() => handleClickOpen()}>Update Report</Button> </>;
                const applyBtn = <><Button type="primary" style={{backgroundColor: "green", borderColor: "green"}}
                                           onClick={() => handleApplyDialogClickOpen(kopRecalculation.id)}>Apply</Button> </>;
                const rejectBtn = <><Button type="primary" style={{backgroundColor: "red", borderColor: "red"}}
                                            onClick={() => onRejectKindOfProductRecalculation(kopRecalculation.id)}>Reject</Button> </>;

                const ttl = new Date();
                ttl.setDate(ttl.getDate() - 180);

                if(ttl.getTime() < new Date(kopRecalculation.createdAt).getTime()) {
                    if ('PENDING_APPROVAL' === kopRecalculation.kopRecalculationStatus.toString()) {
                        return [downloadBtn, updateBtn, applyBtn, rejectBtn];
                    } else if ('INVALID_KOP' === kopRecalculation.kopRecalculationStatus.toString()) {
                        return [downloadBtn, updateBtn, rejectBtn];
                    }
                    else if ('COMPLETED' === kopRecalculation.kopRecalculationStatus.toString() || 'REJECTED' === kopRecalculation.kopRecalculationStatus.toString()) {
                       return downloadBtn;
                    }
                }

                return (<></>);
            }
        }
    ];

    const draggerProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,

        customRequest: (options: RcCustomRequestOptions) => {
            onFileUpload(options.file);
            handleClose();
        },

        beforeUpload(file: File) {

            const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

            if (!isXlsx) {
                message.error('The Kop Recalculation Report must be in XLSX File Format');
                return false;
            } else {
                const isLessThan50Mb = file.size / 1024 / 1024 < 50;
                if (!isLessThan50Mb) {
                    message.error('XLSX file must be smaller than 50 MB');
                }
                return isLessThan50Mb;
            }
        }
    };

    // @ts-ignore
    return props.error ?
        (
            <div className="view-error">{props.error}</div>
        )
        :
        (
            <React.Fragment>

                {
                    loading && (<div>
                        <div className="view-loading"/>
                        <CircularProgress className="view-loading-spinner"/>
                    </div>)
                }

                <Form
                    {...formItemLayout}
                    layout={'horizontal'}
                    form={form}
                >
                    <Form.Item label="Global Product IDs"
                               name={"globalProductIDs"}
                               rules={[{
                                   required: true,
                                   message: 'Please input comma separated list of global product ids'
                               }]}
                    >
                        <Input
                            id="global-product-id-input"
                            value={globalProductIDs}
                            placeholder="Comma separated list of global product ids (Max 10k)"
                            onChange={(event) => onFormFieldChange('globalProductIDs', event.target.value)}
                        />
                    </Form.Item>

                    <Form.Item {...buttonItemLayout}>
                        <Button type="primary" htmlType="submit" onClick={onSubmit}>
                            Recalculate
                        </Button>
                    </Form.Item>
                </Form>
                <EnhancedTable tableName={"Results"}
                               rowsSelectable={false}
                               columns={columns}
                               data={pageResponse.content}
                               totalCount={pageResponse.totalElements}
                               countOnPage={pageResponse.numberOfElements}
                               currentPage={pageRequest.page}
                               pageSize={pageRequest.size}
                               sort={[pageRequest.sort]}
                               onSortingChanged={onSortingChanged}
                               onPaginationChanged={onPaginationChanged}
                />
                <Dialog
                    open={uploadFileDialogOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Update Kop Recalculation Report`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Upload a manually edited version of the Kop Recalculation Report
                            in order to manually edit Proposed KOPs on an individual basis.`}
                        </DialogContentText>
                        <Dragger {...draggerProps} height={300}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Maximum file size is 50 Mb
                            </p>
                        </Dragger>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={applyDialogOpen}
                    onClose={handleApplyDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Apply Kop Recalculation Report?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            By Approving this KOP Prediction Report, I agree that I have observed the relevant report file and am aware that Global Product KOPs will be updated in bulk as proposed in the report.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleApplyDialogClose}>Cancel</Button>
                        <Button onClick={handleApplyDialogConfirm} autoFocus>
                            Approve
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
};

export default KindOfProductRecalculationView;
